import React, { Component, useEffect, useRef, useState } from 'react';
import './Navbar.css';
import { Box, Container, Typography, Link } from '@mui/material';

const Navbar = ({tabClick}) => {
    const active_color = 'var(--team-accent2)'
    const inactive_color = 'black'
    const [tab0, setTab0] = useState(inactive_color)
    const [tab1, setTab1] = useState(inactive_color)
    const [tab2, setTab2] = useState(inactive_color)
    const [tab3, setTab3] = useState(inactive_color)
    const [tab4, setTab4] = useState(inactive_color)
    const [tab5, setTab5] = useState(inactive_color)

    const scrollToSection = (section_id) => {
      const scrollSection = document.getElementById('section_id');
      if (scrollSection) {
        scrollSection.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
        <nav className="nav-desktop" style={{backgroundColor:'none', zIndex:'50'}}>
            <div className="nav-desktop__container" style={{display:'flex', flexDirection:'row',  alignItems:'center', height:'100%'}}>
                <Typography className="nav_menu"  href="/" style={{fontWeight:'bold',width:'auto', fontSize:'30px', font:'roboto', color:tab0, marginLeft:'3%'}} >
                    <Link href="/" underline="none">
                        SUP Athletics
                    </Link>
                </Typography>
                <Typography className="nav_menu" href="/profile" style={{fontWeight:'bold',width:'auto', fontSize:'30px', font:'roboto', color:tab1, marginLeft:'10%'}} >
                    <Link href="/profile" underline="none">
                        Athletes
                    </Link>
                </Typography>
                {/*<Typography className="nav_menu"  style={{fontWeight:'bold',width:'auto', fontSize:'30px', font:'roboto', color:tab2}} value={2} onClick={menuClick}>
                    Training
                </Typography>
                <Typography className="nav_menu"  style={{fontWeight:'bold',width:'auto', fontSize:'30px', font:'roboto', color:tab3}} value={3} onClick={menuClick}>
                    Schedule
                </Typography>
                <Typography className="nav_menu"  style={{fontWeight:'bold',width:'auto', fontSize:'30px', font:'roboto', color:tab4}} value={4} onClick={menuClick}>
                    Coaches/Teams
                </Typography>*/}
            </div>
        </nav>
  )
}


export default Navbar
