import React, {useRef, useEffect, useState} from 'react'

import splash_img1 from "../media/sport-splash-PhotoRoom.png";


const HomeComp = () => {
  const style_prop = {
    display: "block",
    position: "relative",
    transform: "translate3d(0px, 0px, 0px)",
    backgroundColor: "none",
    fonSize:'80px'
  }

  return (
    <div id="slide-0" style={{display:'flex', flexDirection:'column', marginTop:'60px', color:'#000', backgroundColor:'var(--splash-main_bgcolor)'}}>
      <div style={{display:'flex', flexDirection:'row'}}>
       <div style={{width:'45%', maxWidth:'900px',display:'flex',alignItems:'center'}}>
       <h1 id="s-0-h" style={{display:'flex',flexDirection:'column'}}>
          <div class="parent-line" >
            <p id="dtag" style={{fontSize:'40px',margin:'0px'}}>The Student Athlete</p>
          </div>
          <div class="parent-line" >
            <p id="ctag" class="line" style={{fontSize:'40px',margin:'0px'}} >Recruitment Dashboard</p>
          </div>
          <br></br>
          <div style={{paddingLeft:'30px',display:'flex',maxWidth:'900px'}}>
          <p id="smtext" style={{fontFamily:'RobotoFont'}}>Straight Up Athletes is here to help and support student athletes along with their parents in navigating any level of the recruiting process. </p>
          </div>
        </h1>
        </div>
        <div style={{width:'55%', display:'flex',justifyContent:'end'}}>
        <img id="slide_0_graphic" src={splash_img1} /> 
        </div>
       </div>    
    </div>
  )
}


export default HomeComp