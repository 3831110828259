import React, { Component, useEffect, useRef, useState } from 'react';
import './Navbar.css';
import { Box, Container, Typography, Link } from '@mui/material';
import logo from "../media/StraightUpAthleticsLogo.png"
const Navbar_mobile = ({tabClick}) => {
    const active_color = 'var(--team-accent2)'
    const inactive_color = 'black'

    return (
        <nav className="nav-desktop" style={{backgroundColor:'none', zIndex:'50'}}>
            <div className="nav-desktop__container" style={{display:'flex', flexDirection:'row',  alignItems:'center', height:'100%'}}>
                <Typography className="nav_menu"  href="/" style={{fontWeight:'bold',width:'auto', height:'80%', fontSize:'16px', font:'roboto', color:active_color, marginLeft:'1%'}} >
                    <Link href="/" underline="none" style={{height:'80%'}}>
                        <img className="opacity-image" src={logo} style={{height:'80%'}} />
                    </Link>
                </Typography>
                <Typography className="nav_menu" href="/profile" style={{fontWeight:'bold',width:'auto', fontSize:'24px', font:'roboto', color:active_color, marginLeft:'5%'}} >
                    <Link href="/profile" underline="none">
                        Athletes
                    </Link>
                </Typography>
            </div>
        </nav>
  )
}


export default Navbar_mobile
