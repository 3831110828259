import React, { Component, useEffect, useRef, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import {section_comp_style, header_text_style, section_header_style, section_content_style} from "./MediaTab"
import ImageComponent from './carousel/ImageComponent';
import MainSlide from './MainImageSlide'
import img_1 from "../media/brx_logo-PhotoRoom.png"

import performanceVideo from "../media/PR_20231011_145244_session.mp4"

export const name_text_style ={
  display:'flex',
  fontWeight:'bold',
  textAlign: 'center',
  alignItems:'center',
  justifyContent:'center',
  gap:'8px'
}

export const name_text_style_mobile ={
  fontWeight:'bold',
  textAlign: 'center',
  alignItems:'center',
  justifyContent:'center',
  gap:'8px'
}

const PerformanceTab = () => {

  const [currentTab, setCurrentTab] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkScreenSize = () => {
        if (window.innerWidth < 900 || window.outerWidth < 900) {
          setIsMobile(true)
          console.log('screen is mobile device')
        } else {
          setIsMobile(false)
        }
    };
    // Call the checkScreenSize function when the component mounts
    checkScreenSize();
    // Add an event listener to update isMobile on window resize
    window.addEventListener('resize', checkScreenSize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const VideoPlayer = () => {
    return (
      <div>
        <h2></h2>
        <video controls width="640" height="360">
          <source src={performanceVideo} type="video/quicktime" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };
  
  return (
    <div style={section_comp_style} className='section-div'>
      <p className="section-text" style={section_header_style}>Training | Goals | Progression</p>
      <div style={section_content_style}>
        <p className='name-text' style={isMobile ? name_text_style_mobile : name_text_style}> Offseason Program & Coaching: <a href="https://brxperformance.com/" target="_blank"><img className="opacity-image" src={img_1} style={{height:'80%'}} /></a> </p>
        <br/>
        <VideoPlayer/>
      </div>
    </div>
  )
}


export default PerformanceTab