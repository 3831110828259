import React, { Component, useEffect, useRef, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import HomeComp from './HomeComp'
import HomeComp_mobile from './HomeComp_mobile'
import img_1 from "../media/jace_no_bg_1.png"
import img_2 from "../media/jace_no_bg_3.png"
import img_3 from "../media/jace_no_bg_3-PhotoRoom.png"
import './root.css'

const name_text_style ={display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  fontSize:'40px',
  color: 'var(--team-primary)'
  }

const name_text_style_mobile ={display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  fontSize:'15px',
  color:'#ad2335'
  }

const profile_image_style = {
  width:'40%', 
  display:'flex',
  flexDirection:'column',
  position:'fixed',
  left:'0px',
  top:'0px', 
  justifyContent:'center'
}

const home_style = {display:'flex', 
  flexDirection:'column',
  width:'100%', 
  alignContent:'center', 
  alignItems:'center', 
  minHeight:'100vh'
}

const profile_image_style_mobile = {
  width:'80%', 
  display:'flex',
  flexDirection:'column',
}

const HomePage = ({tab}) => {

  const [iLoad, setIload] = useState(null)
  const localImageSrc = 'media-2.png';
  const externalImageSrc = 'https://via.placeholder.com/200x200';
  const [tabNumber, setTabNumber] = useState(null)
  const [showMedia, setShowMedia] = useState(false)
  const [showPer, setShowPer] = useState(false)
  const [showCoaches, setShowCoaches] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkScreenSize = () => {
        if (window.innerWidth < 900 || window.outerWidth < 900) {
          setIsMobile(true)
        } else {
          setIsMobile(false)
        }
    };
    // Call the checkScreenSize function when the component mounts
    checkScreenSize();
    // Add an event listener to update isMobile on window resize
    window.addEventListener('resize', checkScreenSize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div id="main-content-div" style={home_style}>
      {isMobile ? <HomeComp_mobile/> : <HomeComp/>}
    </div>
  )
}


export default HomePage

