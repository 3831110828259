import React, { Component, useEffect, useRef, useState } from 'react';
import { Box, Container, p } from '@mui/material';
import { styled } from "@mui/material/styles";
import ImageComponent from './carousel/ImageComponent';
import MainSlide from './MainImageSlide'
import img_1 from "../media/jace_no_bg_1.png"
import img_2 from "../media/jace_no_bg_3.png"
import img_3 from "../media/jace_no_bg_3-PhotoRoom_2.png"
import headshot from "../media/jace-headshot.png"
import './root.css'

export const name_text_style ={
  display:'flex',
  fontWeight:'bold',
  textAlign: 'center',
  alignItems:'center',
  justifyContent:'center',
  gap:'8px'
}

export const header_text_style = {
  display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  color:'var(--team-primary)'
}

export const head_text_style = {
  display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  color:'var(--team-secondary)'
}

export const section_header_style = {
  display:'flex',
  justifyContent:'end',
  fontWeight:'bold',
  color:'var(--team-secondary)',
  marginRight:'15px'
}

const profile_image_style = {
  width:'40%', 
  flexDirection:'column',
  position:'absolute',
  left:'0px',
  top:'50px', 
  justifyContent:'center'
}

export const section_comp_style = {
  marginTop:'2%',  
  backgroundColor:'white', 
  minHeight:'400px', 
  border:'1px solid var(--team-secondary)',
  marginLeft:'2%',
  marginRight:'2%',

}

export const section_content_style = { 
  display:'flex',
  flexDirection:'column',
  backgroundColor:'none', 
  marginTop:'70px',
  marginBottom:'30px',
  marginLeft:'2%',
  marginRight:'2%',
  alignItems:'center'
}

const pg = "https://www.perfectgame.org/Players/Playerprofile.aspx?ID=871954"
const pbr = "https://www.prepbaseballreport.com/profiles/AL/Jace-Meadows-6297358104"
const x = ""
const ig = ""

const MediaTab = () => {

  const pgl = "Perfect Game:  "
  return (
    <div id="media-comp" className='section-div' style={section_comp_style}>
     <div className="profile-image" style={profile_image_style}>
         <img className="opacity-image" src={img_3} style={{width:'100%'}}/>
         
       </div>
      <div style={section_content_style}>
        <img className="opacity-image" src={headshot} style={{width:'300px',display:'flex',justifyContent:'center'}}/>
        <p className='header-text' style={ head_text_style}>Jace Meadows | RHP | Class 2025</p>
        <p className='header-text' style={header_text_style}> Hartselle High School | Alabama | Class 6A </p>
        <p className='header-text' style={header_text_style}> Bio </p>
        <p className='name-text' style={name_text_style}> Age: 16 yr </p>
        <p className='name-text' style={name_text_style}> Height: 6'1" </p>
        <p className='name-text' style={name_text_style}> Weight: 196 lbs </p>
        <p className='header-text' style={header_text_style}> Scout Profiles </p>
        <p className='name-text' style={name_text_style}> {pgl} <a href={pg} target="_blank"> Profile </a> </p>
        <p className='name-text' style={name_text_style}> Prep Baseball Report: <a href={pbr} target="_blank"> Profile </a> </p>
        
      </div>
    </div>
  )
}


export default MediaTab



// <div style={profile_image_style}>
//         <img className="opacity-image" src={img_3} style={{width:'100%'}}/>
//         <p style={ name_text_style}>Jace Meadows | RHP,OF | Class 2025</p>
//       </div>