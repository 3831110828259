import React, { Component, useEffect, useRef, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import ImageComponent from './carousel/ImageComponent';
import MainSlide from './MainImageSlide'
import {section_comp_style, header_text_style, name_text_style, section_header_style} from "./MediaTab"


const link_style = {
  display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  fontSize:'30px',
  color:'#ad2335'
}

// const team1_link = "https://www.sbgsox.com/"
const team2_link = "https://www.sbgsox.com/"
const team3_link = "https://blacksoxbball.com/"

const CoachesTab = () => {  
  return (
    <div style={section_comp_style} className='section-div'>
      <p className='section-text' style={section_header_style}>Teams | Coaches</p>
      <p className='header-text' style={header_text_style}> Hartselle High School | Alabama | Class 6A </p>
      <p className='name-text' style={name_text_style}> Head Coach: William Booth </p>
      <p className='name-text' style={name_text_style}> Head Coach: Jake Miles </p>
      <p className='header-text' style={header_text_style}> SBG Prime (Summer '23) <a href={team2_link} target="_blank" > sbgsox.com </a></p>
      <p className='name-text' style={name_text_style}> Founder: Chris Snopek (Madison Ridgeland Academy, Jackson Ms) </p>
      <p className='name-text' style={name_text_style}> Head Coach: Chad Lipscomb (Madison Ridgeland Academy, Jackson Ms) </p>
      <p className='header-text' style={header_text_style}> Black Sox (Florence, Al - Summer '22) <a href={team3_link} target="_blank" > blacksoxbball.com </a> </p>
      <p className='name-text' style={name_text_style}> Founder: Randy Howell </p>
      <p className='name-text' style={name_text_style}> Head Coach: Dale Carter </p>
    </div>
  )
}


export default CoachesTab