import React, { useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import Navbar from './Navbar';
import Navbar_mobile from './Navbar_mobile';
import HomePage from './HomePage';
import ProfileListComp from './ProfileListComp';
import ProfileListComp_mobile from './ProfileListComp_mobile';
import TopHeader from './TopHeader';
import Content from './Content'
import {Link, BrowserRouter as Router, Route, Routes, Outlet, useLocation} from 'react-router-dom'


const useStyles = styled(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: '0px'
  },
}));

const style_1 = {
  width:'70%',
  maxWidth:'1800px',
  backgroundColor:'#FCFCFC',
  borderRadius:'20px',
  marginTop:'5%'
}

const style_1_mobile = {
  margin:'0%',
  backgroundColor:'#cecece',
  marginTop:'5%'
}

const main_0 = {
  width:'100vw', 
  minWidth:'2000px', 
  backgroundColor:'#cecece',
  display:'flex',
  justifyContent:'center',
  marginTop: '-100px'
}

const main_0_mobile = {
  width:'100vw',
  backdropFilter: 'blur(10px)', 
  backgroundColor:'var(--team-secondary)',
  overflowX:'clip',
  display:'flex',
  justifyContent:'center'
}


function App() {
  const [currentTab, setCurrentTab] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkScreenSize = () => {
        if (window.innerWidth < 900 || window.outerWidth < 900) {
          setIsMobile(true)
          console.log('screen is mobile device')
        } else {
          setIsMobile(false)
        }
    };
    // Call the checkScreenSize function when the component mounts
    checkScreenSize();
    // Add an event listener to update isMobile on window resize
    window.addEventListener('resize', checkScreenSize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const UpdateTab = (evt) => {
    var txt = evt.target.textContent
    console.log(evt.target.value)
    console.log(evt.target)
    if (txt === 'Bio'){
      setCurrentTab(0)
    } else if (txt === 'Highlights'){
      setCurrentTab(1)
    } else if (txt === 'Training'){
      setCurrentTab(2)
    } else if (txt === 'Schedule'){
      setCurrentTab(3)
    } else if (txt === 'Coaches/Teams'){
      setCurrentTab(4)
    } else if (txt === 'Misc'){
      setCurrentTab(5)
    }
  }

  return (
    <>
    {isMobile ? <Navbar_mobile/> : <Navbar/> }  
    <div className="mb0" id='main-body-0' style={isMobile ? main_0_mobile : main_0}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          {isMobile ? <Route path="/profile" element={<ProfileListComp_mobile/>} /> : <Route path="/profile" element={<ProfileListComp/>} />}
          <Route path="/profile-25-00042" element={<Content tab={currentTab} />}/>
        </Routes>
      </Router>
    </div>
    </>
  );
}

export default App;


// {iLoad ? <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
//         <ImageComponent src='../media/tryout.png' alt_text='yyy'/>
//         <ImageComponent src={img_1} alt_text='yyy'/>
//         <ImageComponent src="https://via.placeholder.com/200x200" alt_text='yyy'/>
//       </div> : null}