import React, { Component, useEffect, useRef, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import {section_comp_style, header_text_style, name_text_style, section_content_style, section_header_style} from "./MediaTab"
import './App.css'
import highlightVideo from "../media/jace_pbr_nationals.mp4"
const art1 = "https://www.perfectgame.org/Articles/View.aspx?article=22114"
const art2 = "https://www.perfectgame.org/DiamondKast/Game.aspx?gameid=820865"
const art3 = "https://www.perfectgame.org/DiamondKast/Game.aspx?gameid=854883"
const art4 = "https://twitter.com/PG_Scouting/status/1672761146615427072"
const high1 = "https://twitter.com/jace_meadows33/status/1679503151836135424"


const HighlightsTab = () => {

  const VideoPlayer = () => {
    return (
      <div style={{marginTop:'15px'}}>
        <h2></h2>
        <video controls width="640" height="360">
          <source src={highlightVideo} type="video/quicktime" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };
  
  return (
    <div id="highlight-section" style={section_comp_style} className='section-div'>
      <p className='section-text' style={section_header_style}>Highlights</p>
      <div style={section_content_style}>
        <p className='header-text' style={header_text_style}> Articles & Mentions </p>
        <p className='name-text' style={name_text_style}> <a href={art1} target="_blank"> {art1} </a> </p>
        <p className='name-text' style={name_text_style}> <a href={art2} target="_blank"> {art2} </a> </p>
        <p className='name-text' style={name_text_style}> <a href={art3} target="_blank"> {art3} </a> </p>
        <VideoPlayer/>
      </div>
    </div>
  )
}


export default HighlightsTab