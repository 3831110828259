import React, { Component, useEffect, useRef, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import ImageComponent from './carousel/ImageComponent';
import MainSlide from './MainImageSlide'
import MediaTab from './MediaTab'
import HighlightsTab from './HighlightsTab'
import PerformanceTab from './PerformanceTab'
import CoachesTab from './CoachesTab'
import ScheduleTab from './ScheduleTab'
import img_1 from "../media/jace_no_bg_1.png"
import img_2 from "../media/jace_no_bg_3.png"
import img_3 from "../media/jace_no_bg_3-PhotoRoom.png"
import './root.css'

const name_text_style ={display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  fontSize:'40px',
  color: 'var(--team-primary)'
  }

const name_text_style_mobile ={display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  fontSize:'15px',
  color:'#ad2335'
  }

const profile_image_style = {
  width:'40%', 
  display:'flex',
  flexDirection:'column',
  position:'fixed',
  left:'0px',
  top:'0px', 
  justifyContent:'center'
}

const profile_image_style_mobile = {
  width:'80%', 
  display:'flex',
  flexDirection:'column',
}

const Content = ({tab}) => {

  const [iLoad, setIload] = useState(null)
  const localImageSrc = 'media-2.png';
  const externalImageSrc = 'https://via.placeholder.com/200x200';
  const [tabNumber, setTabNumber] = useState(null)
  const [showMedia, setShowMedia] = useState(false)
  const [showPer, setShowPer] = useState(false)
  const [showCoaches, setShowCoaches] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkScreenSize = () => {
        if (window.innerWidth < 900 || window.outerWidth < 900) {
          setIsMobile(true)
        } else {
          setIsMobile(false)
        }
    };
    // Call the checkScreenSize function when the component mounts
    checkScreenSize();
    // Add an event listener to update isMobile on window resize
    window.addEventListener('resize', checkScreenSize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  useEffect(()=>{
    if (img_2){
      console.log(img_2)
      setIload(true)
    }
  },[iLoad])

  useEffect(()=>{
    setShowMedia(false)
    setShowPer(false)
    setShowCoaches(false)
    console.log(tab)
    if (tab === 0){
      setShowMedia(true)
    }else if (tab === 1){
      setShowPer(true)
    } else if (tab === 4){
      setShowCoaches(true)
    }

  })
  
  return (
    <div id="main-content-div" style={{display:'flex', flexDirection:'column',width:'100%', alignContent:'center', alignItems:'center'}}>
      <MediaTab/>
      <HighlightsTab/>
      <PerformanceTab/>
      <CoachesTab/>
{/*      <ScheduleTab/>*/}
    </div>
  )
}


export default Content



// {showMedia ? <MediaTab/> : null}
//       {showPer ? <HighlightsTab/> : null}
//       {showPer ? <PerformanceTab/> : null}
//       {showCoaches ? <CoachesTab/> : null}