import React, {useRef, useEffect, useState} from 'react'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Typography, Link } from '@mui/material';


const ProfileListComp_mobile = () => {
  const style_prop = {
    display: "block",
    position: "relative",
    transform: "translate3d(0px, 0px, 0px)",
    backgroundColor: "none",
    fonSize:'80px'
  }

  function renderNameAsLink(params) {
    const name = params.row.id;
    console.log(params.row)
    return <a href={`/profile-${name}`}>{params.row.name}</a>;
  }


  const columns: GridColDef[] = [
  { field: 'name', headerName: 'Athlete', width: 150,renderCell: renderNameAsLink },
  { field: 'grad', headerName: 'Grad Year', type: 'text',width: 100},
  { field: 'sport', headerName: 'Sport', type: 'text',width: 200},
];

const rows = [
  { id: "25-00042",name:'Jace Meadows', lastName: 'Meadows', firstName: 'Jace',  grad:'2025', sport:'baseball',position:'rhp',shcool:'Hartselle', state:'Alabama' },
  { id: "25-00082",name:'Jack Osby', lastName: 'Osby', firstName: 'Jack',  grad:'2025', sport:'rodeo',position:'Saddle Bronc',shcool:'Priceville', state:'Alabama'  },
];

  return (
    <div id="profile-list-page" style={{display:'flex', flexDirection:'column', marginTop:'160px', color:'#000', backgroundColor:'var(--splash-main_bgcolor)', minHeight:'100vh'}}>
      <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
         <div style={{ width: '80%', fontSize:'12px', justifyContent:'center',alignItems:'center' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[10]}
            style={{fontSize:'16px', fontFamily:'RobotoFont', textAlign:'center',display:'flex',justifyContent:'center', backgroundColor:'#FFFFFF'}}
          />
        </div>
       </div>    
    </div>
  )
}


export default ProfileListComp_mobile