import React, {useRef, useEffect, useState} from 'react'
import splash_img1 from "../media/sport-splash-PhotoRoom.png";


const HomeComp_mobile = () => {


  return (
    <div style={{width:'100vw', display:'flex',  marginTop:'90px', color:'#fff', backgroundColor:'var(--splash-main_bgcolor)'}}>
      <div style={{display:'flex', flexDirection:'column', alignItems:'center',width:'100%'}}>
       <div style={{width:'85%',display:'flex',alignItems:'center', justifyContent:'center'}}>
       <h1  style={{display:'flex',flexDirection:'column', width:'90%'}}>
          <p id="dtag" style={{fontSize:'40px',margin:'0px'}}>The Student Athlete</p>
          <p id="ctag" class="line" style={{fontSize:'40px',margin:'0px'}} >Recruitment Dashboard</p>
          <br></br>
          <div style={{paddingLeft:'0px',display:'flex',}}>
          <p id="smtext" style={{fontFamily:'RobotoFont'}}>Straight Up Athletes is here to help and support student athletes along with their parents in navigating any level of the recruiting process. </p>
          </div>
        </h1>
        </div>
        <div style={{width:'55%', display:'flex',justifyContent:'end'}}>
        <img id="slide_0_graphic" src={splash_img1} style={{width:'100%'}} /> 
        </div>
       </div>    
    </div>
  )
}


export default HomeComp_mobile